.main-layout {
  background-image: url(./static/background2.jpg);
  background-size: contain;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #f6f3ec;
}

.ant-spin-dot-item {
  background-color: #ab512e;
}
.ant-spin-text {
  margin-top: 5px;
  color: #ab512e;
  font-weight: bold;
}

h1,
h2 {
  color: #ab512e;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card-container .custom-card {
  width: 400px;
  max-height: 450px;
  box-shadow: rgb(187 143 40 / 0.4) 0px 0px 20px;
  border-radius: 5px;
  text-align: center;
  margin: auto;
}

.sound-wave {
  width: 100%;
  height: 100px;
}

.or-section-divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.or-section-divider .or-text {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e2e5e9;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  z-index: 1;
  background-color: #fff;
}

.or-section-divider .or-line {
  position: absolute;
  background-color: #e2e5e9;
  height: 2px;
  left: 0;
  right: 0;
}

.text-ash {
  color: #6c757d !important;
}

.bootstrap-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.social-links-section {
  display: flex;
  margin-bottom: 15px;
  text-align: left;
}

.social-links-section div {
  flex: 50%;
}

.social-buttons-section button {
  margin-right: 5px;
  border: none;
  border-radius: 8px;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
}

.facebook-btn {
  background-color: rgb(59, 89, 152);
}
.facebook-btn:hover {
  background-color: rgba(59, 89, 152, 0.9);
}

.twitter-btn {
  background-color: rgb(0, 172, 237);
}
.twitter-btn:hover {
  background-color: rgba(0, 172, 237, 0.9);
}

.whatsapp-btn {
  background-color: rgb(44, 183, 66);
}
.whatsapp-btn {
  background-color: rgba(44, 183, 66, 0.9);
}

.email-btn {
  background-color: rgb(127, 127, 127);
}
.email-btn:hover {
  background-color: rgb(127, 127, 127, 0.9);
}

footer {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  text-align: center;
}

footer .footer-text {
  font-size: 1rem;
  font-weight: 600;
  color: #ab512e;
  background-color: #fff;
  padding: 5px 15px;
  box-shadow: rgb(187 143 40 / 0.4) 0px 0px 20px;
}

.welcome-section {
  text-align: center;
  font-size: 1.5rem;
}

@media (max-width: 576px) {
  .bootstrap-container {
    max-width: 540px;
  }
  .card-container {
    align-items: unset;
    padding-top: 25px;
  }

  .card-container .custom-card {
    min-width: 200px;
    width: unset;
  }

  .welcome-section {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .bootstrap-container {
    max-width: 720px;
  }
}

@media (max-width: 992px) {
  .bootstrap-container {
    max-width: 960px;
  }
}

@media (max-width: 1200px) {
  .bootstrap-container {
    max-width: 1140px;
  }
}
